import { ICommonFilter } from "@/types/Filters";

const investmentData: ICommonFilter[] = [
    {
        label: "Angel",
        value: "4",
    },
    {
        label: "Company (CVC)",
        value: "3",
    },
    {
        label: "Private equity (PE)",
        value: "2",
    },
    {
        label: "Venture capital (VC)",
        value: "1",
    },
    {
        label: "No investment",
        value: "5",
    },
];

const growthData: ICommonFilter[] = [
    {
        label: "Yes",
        value: "1",
    },
    {
        label: "No",
        value: "0",
    },
];

const importerData: ICommonFilter[] = [
    {
        label: "Is an importer",
        value: "1",
    },
    {
        label: "Is not an importer",
        value: "0",
    },
];

const exporterData: ICommonFilter[] = [
    {
        label: "Is an exporter",
        value: "1",
    },
    {
        label: "Is not an exporter",
        value: "0",
    },
];

const secureDebtData: ICommonFilter[] = [
    {
        label: "Has Secured debt",
        value: "1",
    },
    {
        label: "Does not have Secure debt",
        value: "0",
    },
];

export {
    investmentData,
    growthData,
    importerData,
    exporterData,
    secureDebtData,
};
