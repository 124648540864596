enum FilterTypeEnum {
    RADIO_SELECT = "RADIO_SELECT",
    MULTI_SELECT_CHECKBOX = "MULTI_SELECT_CHECKBOX",
    NESTED_MULTI_SELECT_CHECKBOX = "NESTED_MULTI_SELECT_CHECKBOX",
}

enum InvestmentEnum {
    "id" = "id",
    "name" = "name",
    "gender_count" = "gender_count",
    "total_investments" = "total_investments",
    "company_count" = "company_count",
    "gender_percentage" = "gender_percentage",
}

enum GenerationEnum {
    company_count = "company_count",
    gender_count = "gender_count",
    gender_percentage = "gender_percentage",
    id = "id",
    name = "name"
}

export {
    FilterTypeEnum,
    InvestmentEnum,
    GenerationEnum,
};
