import { FilterTypeEnum } from "@/enum/filters";
import { IFilterJson, IMainFilter } from "@/types/Filters";

import { companyAgeData, ethnicityData, genderData, generationData } from "./filter-data/demographics";
import { exporterData, growthData, importerData, investmentData, secureDebtData } from "./filter-data/growth";
import { sectorsData } from "./filter-data/industry";
import { locationDataByKey } from "./filter-data/location";

const mainFilterJson: IMainFilter[] = [
    {
        name: "Locations",
        children: [
            {
                id: "LL001",
                name: "UK nations",
                type: FilterTypeEnum.MULTI_SELECT_CHECKBOX,
                title: "UK nations",
                data: locationDataByKey.ukCountries,
                hasSearch: true,
                dependsOn: ["LL002", "LL004", "LL005"],
                tooltipText: "Show companies based on the UK country they are registered in.",
            },
            {
                id: "LL002",
                name: "Regions",
                type: FilterTypeEnum.MULTI_SELECT_CHECKBOX,
                title: "Regions",
                data: locationDataByKey.region,
                hasSearch: true,
                dependsOn: ["LL001", "LL004", "LL005"],
                tooltipText: "Show companies based on the UK region they are registered in.",
            },
            // {
            //     id: "LL003",
            //     name: "LEPs",
            //     type: FilterTypeEnum.MULTI_SELECT_CHECKBOX,
            //     title: "LEPs",
            //     data: locationDataByKey.localEnterprisePartnership,
            //     hasSearch: true,
            //     dependsOn: ["LL001", "LL002", "LL004"],
            //     tooltipText: "Show companies based on the local enterprise partnership (LEP) they are registered in.",
            // },
            {
                id: "LL004",
                name: "Local authorities",
                type: FilterTypeEnum.MULTI_SELECT_CHECKBOX,
                title: "Local authorities",
                data: locationDataByKey.localAuthority,
                hasSearch: true,
                dependsOn: ["LL001", "LL002", "LL005"],
                tooltipText: "Show companies based on the local authority they are registered in.",
            },
            {
                id: "LL005",
                name: "Constituencies",
                type: FilterTypeEnum.MULTI_SELECT_CHECKBOX,
                title: "Constituencies",
                data: locationDataByKey.constituencies,
                hasSearch: true,
                dependsOn: ["LL001", "LL002", "LL004"],
                tooltipText: "Select the Westminster constituency that the companies are located in",
            },
        ],
    },
    {
        name: "Key details",
        children: [
            {
                id: "D001",
                name: "Gender",
                type: FilterTypeEnum.RADIO_SELECT,
                title: "Gender",
                subTitle: "Results will show companies led by your selected gender.",
                data: genderData,
                tooltipText: "Select companies based on the gender of their active directors.",
            },
            {
                id: "D004",
                name: "Company age",
                type: FilterTypeEnum.MULTI_SELECT_CHECKBOX,
                title: "Company age",
                subTitle: "Find the companies based on the company age",
                data: companyAgeData,
                hasSearch: false,
                tooltipText: "Filter by age bands. Select multiple bands to create a wider range.",
            },
            {
                id: "D002",
                name: "Ethnicity",
                type: FilterTypeEnum.MULTI_SELECT_CHECKBOX,
                title: "Ethnicity",
                subTitle: "Results will show companies led by your selected ethnicity.",
                data: ethnicityData,
                hasSearch: false,
                tooltipText: "Select the companies ethnic breakdown.",
            },
            {
                id: "G002",
                name: "Fast growth",
                type: FilterTypeEnum.MULTI_SELECT_CHECKBOX,
                title: "Growth",
                subTitle: "Show only companies who have registered +20% growth in the last accountable 24 month period.",
                data: growthData,
                hasSearch: false,
                tooltipText: "Show companies who have registered +20% growth in the last accountable 24 month period.",
            },
            {
                id: "D003",
                name: "Generation",
                type: FilterTypeEnum.MULTI_SELECT_CHECKBOX,
                title: "Generation",
                subTitle: "Find the companies based on the company generation",
                data: generationData,
                hasSearch: false,
                tooltipText: "Select companies based on the average generational age of its active directors.",
            },
            {
                id: "G001",
                name: "Investment",
                type: FilterTypeEnum.MULTI_SELECT_CHECKBOX,
                title: "Investment",
                subTitle: "Find the type of invesment received by the company.",
                data: investmentData,
                hasSearch: false,
                tooltipText: "Show companies which have received one of the selected types of investment.",
            },
            {
                id: "I001",
                name: "Sectors",
                type: FilterTypeEnum.MULTI_SELECT_CHECKBOX,
                title: "Select sectors",
                subTitle: "Filter by specific by selecting them below.",
                data: sectorsData,
                hasSearch: true,
                tooltipText: "Show companies based on the sector they operate in.",
            },
            {
                id: "E001",
                name: "Importers",
                type: FilterTypeEnum.MULTI_SELECT_CHECKBOX,
                data: importerData,
                title: "Importers",
                hasSearch: false,
                subTitle: "Select companies involved in importing",
                tooltipText: "Select companies involved in importing",
            },
            {
                id: "E002",
                name: "Exporters",
                type: FilterTypeEnum.MULTI_SELECT_CHECKBOX,
                data: exporterData,
                title: "Exporters",
                hasSearch: false,
                subTitle: "Select companies involved in exporting",
                tooltipText: "Select companies involved in exporting",
            },
            {
                id: "E003",
                name: "Secured debt",
                title: "Secured debt",
                type: FilterTypeEnum.MULTI_SELECT_CHECKBOX,
                data: secureDebtData,
                hasSearch: false,
                subTitle: "Select companies that have a Secured debt charged against them. Commonly seen as mortgages or vehicle finance.",
                tooltipText: "Select companies that have a Secured debt charged against them. Commonly seen as mortgages or vehicle finance.",
            },
        ],
    },
];

const notIncludeAllFilterList = ["D004", "D002", "G002", "D003", "G001", "I001"];

const FILTER_KEYS = {
    "UK nations": "LL001",
    "Regions": "LL002",
    "LEPs": "LL003",
    "Local authorities": "LL004",
    "Constituencies": "LL005",
    "Gender": "D001",
    "Ethnicity": "D002",
    "Company age": "D004",
    "Generation": "D003",
    "Investment": "G001",
    "Fast growth": "G002",
    "Sectors": "I001",
    "Importers": "E001",
    "Exporters": "E002",
    "Secured debt": "E003",
};

function getInitialMainFilter() {
    return {
        "D001": {
            data: genderData.filter(i => i.label.toLowerCase() === "female"),
        },
        "LL002": {
            "data": locationDataByKey.region,
            all: true,
        },
    };
}

function transformToOurFilter(arr: any[]) {
    const obj: any = {};
    arr.forEach((item) => {
        obj[item.id] = item.data;
    });
    return obj;
}

function transformMainFilter(searchFilter: any) {
    const obj: IFilterJson[] = [];
    if (searchFilter) {
        for (const key in searchFilter) {
            obj.push({
                id: key,
                data: searchFilter[key],
            });
        }
    }
    return obj;

}

function getFilterFromJsonString(searchFilter: any) {
    if (searchFilter) {
        const f = JSON.parse(searchFilter);
        const cf: any = {};
        f.forEach((i: any) => {
            cf[i.id] = i;
        });
        return cf;
    }
    return {};
}

export {
    mainFilterJson,
    getInitialMainFilter,
    getFilterFromJsonString,
    transformMainFilter,
    transformToOurFilter,
    notIncludeAllFilterList,
    FILTER_KEYS,
};
