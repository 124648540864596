import LocalAuthorities from "@/features/explore/maps/json/LocalAuthorities.json";
import constituenciesJson from "@/features/explore/maps/json/reference_westminster_constituencies.json";
const locationDataByKey = {
    ukCountries: [
        {
            "value": "1",
            "label": "England",
        },
        {
            "value": "2",
            "label": "Scotland",
        },
        {
            "value": "3",
            "label": "Wales",
        },
        {
            "value": "4",
            "label": "Northern Ireland",
        },
    ],
    region: [
        {
            "value": 1,
            "label": "East of England",
        },
        {
            "value": 2,
            "label": "East Midlands",
        },
        {
            "value": 3,
            "label": "London",
        },
        {
            "value": 4,
            "label": "North East",
        },
        {
            "value": 5,
            "label": "North West",
        },
        {
            "value": 6,
            "label": "Northern Ireland",
        },
        {
            "value": 7,
            "label": "Scotland",
        },
        {
            "value": 8,
            "label": "South East",
        },
        {
            "value": 9,
            "label": "South West",
        },
        {
            "value": 10,
            "label": "Wales",
        },
        {
            "value": 11,
            "label": "West Midlands",
        },
        {
            "value": 12,
            "label": "Yorkshire and The Humber",
        },
    ],
    localEnterprisePartnership: [
        {
            "value": 1,
            // "value": "E37000001",
            "label": "Black Country",
        },
        {
            "value": 2,
            // "value": "E37000043",
            "label": "Buckinghamshire Thames Valley",
        },
        {
            "value": 3,
            // "value": "E37000003",
            "label": "Cheshire and Warrington",
        },
        {
            "value": 4,
            // "value": "E37000044",
            "label": "Coast to Capital",
        },
        {
            "value": 5,
            // "value": "E37000005",
            "label": "Cornwall and Isles of Scilly",
        },
        {
            "value": 6,
            // "value": "E37000006",
            "label": "Coventry and Warwickshire",
        },
        {
            "value": 7,
            // "value": "E37000007",
            "label": "Cumbria",
        },
        {
            "value": 8,
            // "value": "E37000045",
            "label": "Derby, Derbyshire, Nottingham and Nottinghamshire",
        },
        {
            "value": 9,
            // "value": "E37000009",
            "label": "Dorset",
        },
        {
            "value": 10,
            // "value": "E37000046",
            "label": "Enterprise M3",
        },
        {
            "value": 11,
            // "value": "E37000011",
            "label": "Gloucestershire",
        },
        {
            "value": 12,
            // "value": "E37000012",
            "label": "Greater Birmingham and Solihull",
        },
        {
            "value": 13,
            // "value": "E37000059",
            "label": "Greater Cambridge and Greater Peterborough",
        },
        {
            "value": 14,
            // "value": "E37000060",
            "label": "Greater Lincolnshire",
        },
        {
            "value": 15,
            // "value": "E37000015",
            "label": "Greater Manchester",
        },
        {
            "value": 16,
            // "value": "E37000016",
            "label": "Heart of the South West",
        },
        {
            "value": 17,
            // "value": "E37000048",
            "label": "Hertfordshire",
        },
        {
            "value": 18,
            // "value": "E37000061",
            "label": "Hull and East Riding",
        },
        {
            "value": 19,
            // "value": "E37000019",
            "label": "Lancashire",
        },
        {
            "value": 20,
            // "value": "E37000062",
            "label": "Leeds City Region",
        },
        {
            "value": 21,
            // "value": "E37000021",
            "label": "Leicester and Leicestershire",
        },
        {
            "value": 22,
            // "value": "E37000022",
            "label": "Liverpool City Region",
        },
        {
            "value": 23,
            // "value": "E37000051",
            "label": "London",
        },
        {
            "value": 24,
            // "value": "E37000052",
            "label": "New Anglia",
        },
        {
            "value": 25,
            // "value": "E37000025",
            "label": "North East",
        },
        {
            "value": 26,
            // "value": "E37000053",
            "label": "Oxfordshire",
        },
        {
            "value": 27,
            // "value": "E37000054",
            "label": "Sheffield City Region",
        },
        {
            "value": 28,
            // "value": "E37000055",
            "label": "Solent",
        },
        {
            "value": 29,
            // "value": "E37000056",
            "label": "South East",
        },
        {
            "value": 30,
            // "value": "E37000057",
            "label": "South East Midlands",
        },
        {
            "value": 31,
            // "value": "E37000032",
            "label": "Stoke-on-Trent and Staffordshire",
        },
        {
            "value": 32,
            // "value": "E37000033",
            "label": "Swindon and Wiltshire",
        },
        {
            "value": 33,
            // "value": "E37000034",
            "label": "Tees Valley",
        },
        {
            "value": 34,
            // "value": "E37000035",
            "label": "Thames Valley Berkshire",
        },
        {
            "value": 35,
            // "value": "E37000036",
            "label": "The Marches",
        },
        {
            "value": 36,
            // "value": "E37000037",
            "label": "West of England",
        },
        {
            "value": 37,
            // "value": "E37000038",
            "label": "Worcestershire",
        },
        {
            "value": 38,
            // "value": "E37000058",
            "label": "York and North Yorkshire",
        },
    ],
    localAuthority: LocalAuthorities.map(i => (
        {
            label: i.local_authority_name,
            value: i.id_local_authority,
        }
    )),
    constituencies: constituenciesJson.map(i => ({
        label: i.constituency_name,
        value: i.id_constituency,
    })).sort((a, b) => a.label > b.label ? 1 : -1),
};
const locationData = [
    {
        name: "UK countries",
        children: locationDataByKey.ukCountries,
    },
    {
        name: "Region",
        children: locationDataByKey.region,
    },
    {
        name: "Local enterprise partnership",
        children: locationDataByKey.localEnterprisePartnership,
    },
    {
        name: "Local authority",
        children: locationDataByKey.localAuthority,
    },
];

export {
    locationData,
    locationDataByKey,
};
