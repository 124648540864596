import { ICommonFilter } from "@/types/Filters";

const sectorsData: ICommonFilter[] = [
    {
        value: "1",
        label: "Agriculture, forestry and fishing",
    },
    {
        value: "2",
        label: "Mining and quarrying",
    },
    {
        value: "3",
        label: "Manufacturing",
    },
    {
        value: "4",
        label: "Energy suppliers",
    },
    {
        value: "5",
        label: "Water and waste services",
    },
    {
        value: "6",
        label: "Construction",
    },
    {
        value: "7",
        label: "Wholesale and retail",
    },
    {
        value: "8",
        label: "Logistics and storage services",
    },
    {
        value: "9",
        label: "Accommodation and food service activities",
    },
    {
        value: "10",
        label: "Information, communication and technology",
    },
    {
        value: "11",
        label: "Financial services",
    },
    {
        value: "12",
        label: "Real estate activities",
    },
    {
        value: "13",
        label: "Professional, scientific and technical services",
    },
    {
        value: "14",
        label: "Administrative and support service activities",
    },
    {
        value: "15",
        label: "Public health and safety services",
    },
    {
        value: "16",
        label: "Education",
    },
    {
        value: "17",
        label: "Health, wellbeing and social care",
    },
    {
        value: "18",
        label: "Arts, entertainment and recreation",
    },
    {
        value: "19",
        label: "Service sector",
    },
    {
        value: "20",
        label: "Household employers",
    },
    {
        value: "21",
        label: "Overseas organisations",
    },
];
sectorsData.sort((a, b) => a.label > b.label ? 1 : -1);
export {
    sectorsData,
};
