import { ICommonFilter } from "@/types/Filters";

const genderData: ICommonFilter[] = [
    {
        label: "Female",
        value: "1, 5",
    },
    {
        label: "Male",
        value: "2, 6",
    },
    {
        label: "Mixed",
        value: "3, 4, 7, 8, 10",
    },
    {
        label: "Unknown",
        value: "9",
    },
];

const ethnicityData: ICommonFilter[] = [
    {
        label: "Ethnic-led",
        value: "1",
    },
    {
        label: "Non Ethnic-led",
        value: "0",
    },
];

const generationData: ICommonFilter[] = [
    {
        label: "Generation Z",
        value: "1",
    },
    {
        label: "Millennials",
        value: "2",
    },
    {
        label: "Generation X",
        value: "3",
    },
    {
        label: "Boomers",
        value: "4",
    },
    {
        label: "Silent",
        value: "5",
    },
];

const companyAgeData: ICommonFilter[] = [
    {
        label: "0-2 Years",
        value: "1",
    },
    {
        label: "3-5 Years",
        value: "2",
    },
    {
        label: "6-10 Years",
        value: "3",
    },
    {
        label: "11-20 Years",
        value: "4",
    },
    {
        label: "21-30 Years",
        value: "5",
    },
    {
        label: "31-40 Years",
        value: "6",
    },
    {
        label: "41-50 Years",
        value: "7",
    },
    {
        label: "51+ Years",
        value: "8",
    },
];
export {
    genderData,
    ethnicityData,
    generationData,
    companyAgeData,
};
