/* eslint-disable max-len */
import Head from "next/head";

import { getWebsiteUrl } from "@/constants";

interface IStaticPageSeo {
    title?: string;
    description?: string;
    url?: string;
    canonicalUrl?: string;
    image?: string;
    pathname?: string;
}
const DEFAULT_META_OPTIONS = {
    title: "The Gender Index",
    // description: "Currently 17.78% of active UK companies are female-led. Our aim is to significantly increase that number using our gender disaggregated data to inspire positive change for women in enterprise. ",
    description: "Unlocking insights into female entrepreneurship across the UK. Dive into transparent gender-disaggregated data with The Gender Index. Explore its impact on sectors, investment, diversity, and more through engaging data stories and dashboards. Empowering change, one data point at a time.",
};
function PageSeo({
    url = getWebsiteUrl(),
    title = DEFAULT_META_OPTIONS.title,
    description = DEFAULT_META_OPTIONS.description,
    image = `${getWebsiteUrl()}/images/TGI-logo.jpg`,
    canonicalUrl,
    pathname,
}: IStaticPageSeo) {
    url = pathname ? `${url}${pathname}` : url;
    canonicalUrl = canonicalUrl ? canonicalUrl : url;
    return (
        <Head>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={description} />
            <meta name="robots" content="index, follow" />
            <meta name="revisit-after" content="15 days" />
            <link rel="canonical" href={canonicalUrl} />
            {/* Open Graph / Facebook */}
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_GB" />
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            {/* Twitter */}
            <meta property="og:site_name" content="The Gender Index" />
            <meta property="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@TheGenderIndex" />
            <meta property="twitter:url" content={url} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
            <meta property="twitter:image" content={image} />
        </Head>
    );
}

export default PageSeo;
