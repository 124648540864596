import React from "react";

class HeatSpotColours {

    /**
     * Least dense colour
     */
    static leastDenseColour = "rgb(73, 166, 180)";

    /**
     * Most dense colour
     */
    static mostDenseColour = "rgb(236, 122, 123)";

    /**
     * Gradient Steps
     */
    static gradientSteps = 10;

    /**
     * Returns a single rgb color interpolation between given rgb color based on the factor given
     *
     * @param color1
     * @param color2
     * @param factor
     */
    static interpolateColor = (color1: Array<number>, color2: Array<number>, factor = 0.5): Array<number> => {
        const result = color1.slice();

        for (let i = 0; i < 3; i++) {
            result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
        }

        return result;
    };

    /**
     * Interpolate between two colors completely, returning an array
     *
     * @param steps
     */
    static interpolateColors = (steps?: number): Array<Array<number>> => {
        if (typeof steps !== "number") {
            steps = HeatSpotColours.gradientSteps;
        }

        const stepFactor = 1 / (steps - 1);
        const interpolatedColorArray: Array<Array<number>> = [];
        const translatedColor1 = HeatSpotColours.mostDenseColour.match(/\d+/g)?.map(Number);
        const translatedColor2 = HeatSpotColours.leastDenseColour.match(/\d+/g)?.map(Number);

        if (Array.isArray(translatedColor1) && Array.isArray(translatedColor2)) {
            for (let i = 0; i < steps; i++) {
                interpolatedColorArray.push(HeatSpotColours.interpolateColor(translatedColor1, translatedColor2, stepFactor * i));
            }
        }

        return interpolatedColorArray;
    };

    /**
     * Get RGB String of the colour you want from the gradient
     *
     * @param colourNumber
     */
    static getHeatSpotColourByNumber(colourNumber: number): string {
        const colours = HeatSpotColours.interpolateColors(HeatSpotColours.gradientSteps);
        let colour = colours[0];

        if (colourNumber > 0)
            colour = colours[colourNumber];

        return `rgb(${colour[0]},${colour[1]},${colour[2]})`;
    }

    /**
     * Get a Heat Spot Colour Sample JSX Element
     *
     * @param colourNumber
     */
    static getHeatSpotSampleCircle(colourNumber: number): JSX.Element {
        const colour = HeatSpotColours.getHeatSpotColourByNumber(colourNumber);

        return (
            <React.Fragment>
                <div
                    className="rounded-circle p-10 heatSpotCircle"
                    style={{
                        backgroundColor: colour,
                    }}>
                </div>
            </React.Fragment>
        );
    }
}

export default HeatSpotColours;
